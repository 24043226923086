import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../constant';
import Loading from './Loading';
import Point from './Point';
import { generateRandomString } from '../utils';

const UserCount = ({count}) => {
  const tottal = 1000000 + parseInt(count);
  return (
    <div className='holder-number'>{count ? (`(${tottal}) HOLDERS`) : "caculating..."}</div>
  );
}
const LeaderBoard = ({user, userCount}) => {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const currentUserName = user.userName || generateRandomString(8);

  const fetchUsers = async () => {
    try {
        const response = await axios.get(`${baseUrl}/leaderboard`);
        axios.get(`${baseUrl}/userRank/${user.totalPoints}`).then( res => {
          const {data: { rank = 0 } = {}} = res || {};
          setUserRank(rank);
        });
        return {data: response.data};
    } catch (error) {
        console.error('Error fetching joined users:', error);
    }
  };

  useEffect( () => {
    fetchUsers().then( (res) => {
      setUserList(res.data);
      setLoading(false);
    });
  }, []);
  return (
    <div className='leaderboard-page'>
      {
        loading ? (  <Loading loadingText={"Calculating"} /> ) : (
          <div>
            <div className='title'>
              <h2>CRICKET<br/><span>WALL OF FRAME</span></h2>
            </div>
            <div className='current-user user-item'>
              <div className='left-item'>
                <p className='user-name'>{currentUserName}</p>
                <p className='point-number'>{user.totalPoints} points</p>
              </div>
              <div className='right-item user-rank'>#{userRank ? userRank : " caculating..."}</div>
            </div>
            <UserCount count={userCount} />
            <div className='user-list'>
              {userList.length > 0 && userList.map( (userItem, key) => (
                <div key={key} className='user-item'>
                  <div className='left-item'>
                    {key === 0 && <span className='user-no'>🥇</span>}
                    {key === 1 && <span className='user-no'>🥈</span>}
                    {key === 2 && <span className='user-no'>🥉</span>}
                    {key > 2 && <span className='user-no'>#{key + 1}</span>}
                    <span className='user-name'>{user.userId === userItem.userId ? currentUserName : userItem.userName || generateRandomString(8)}</span>
                  </div>
                  <div className='right-item'><Point point={userItem.totalPoints}/></div>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default LeaderBoard;
