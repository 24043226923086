import { useState } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { baseUrl } from '../constant';
import '../css/SliderCarousel.css'

const ACTION_URL = {
  'joinChannel': 'https://t.me/Cworldchannel',
  'joinChat': 'https://t.me/CWorldOfficial',
  'joinMarket': 'http://cwnfts.io/',
  'joinGame': 'https://cwgame.io/',
  'joinWhitePaper': 'https://cwgame.io/storage/files/DrTgqsYHadhXh35POhhqtsBaB8pPwgBItLwqC9ev.pdf',
  'followX': 'https://twitter.com/cwgame_io',
  'followTiktok': 'https://www.tiktok.com/@klcworld?_t=8nvMn9Sszzh&_r=1',
  'followYoutube': 'https://www.youtube.com/@Cwgamefi',
  'followBinance': 'https://app.binance.com/uni-qr/cpro/KLinkCW?l=vi&r=532445369&uc=app_square_share_link&us=copylink'
}

const SliderCarousel = ({userId}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
      "joinChannel",
      "joinChat",
      // "joinMarket",
      // "joinGame",
      "joinWhitePaper",
      "followX",
      "followTiktok",
      "followYoutube",
      "followBinance",
  ];

  const handleSelect = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };
  
  const handleClick = (userId, actionType) => {
    handleAction(userId, actionType);
    window.open(ACTION_URL[actionType], '_blank', 'noopener,noreferrer');
  };

  const handleAction = async (userId, actionType) => {
    try {
      console.log("user action", userId, actionType);
      await axios.post(`${baseUrl}/addPoints`, {
          "userId": userId,
          "actionType": actionType
      });
    } catch (error) {
        console.error('Error fetching joined users:', error);
    }
  };

  return (
    <Carousel activeIndex={currentIndex} onSelect={handleSelect}>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          {
              slide === 'joinChannel' && (
                  <div className='slide-item'>
                      <div className='channel-title'>C•WORLD <span>community</span></div>
                      <div className='channel-desc'>Home for Telegram OGs</div>
                      <button onClick={() => handleClick(userId, slide)}>Go to Channel</button>
                  </div>)
          }
          {
              slide === 'joinChat' && (
                  <div className='slide-item'>
                      <div className='channel-title'>C•WORLD <span>chat</span></div>
                      <div className='channel-desc'>Home for C World community</div>
                      <button onClick={() => handleClick(userId, slide)}>Visit</button>
                  </div>)
          }
          {
              slide === 'joinMarket' && (
                  <div className='slide-item'>
                      <div className='channel-title'>C•WORLD <span>market place</span></div>
                      <div className='channel-desc'>C World simulation world metaverse</div>
                      <button onClick={() => handleClick(userId, slide)}>Visit</button>
                  </div>)
          }
          {
              slide === 'joinGame' && (
                  <div className='slide-item'>
                      <div className='channel-title'>C•WORLD <span>game</span></div>
                      <div className='channel-desc'>Home for Telegram OGs</div>
                      <button onClick={() => handleClick(userId, slide)}>Visit</button>
                  </div>)
          }
          {
              slide === 'joinWhitePaper' && (
                  <div className='slide-item'>
                      <div className='channel-title'>C•WORLD <span>white paper</span></div>
                      <div className='channel-desc'>Home for Telegram OGs</div>
                      <button onClick={() => handleClick(userId, slide)}>Visit</button>
                  </div>)
          }
          {
              slide === 'followX' && (
                  <div className='slide-item'>
                      <div className='channel-title'>FOLLOW US ON X</div>
                      <div className='channel-desc'>Stay updated with the latest news</div>
                      <button onClick={() => handleClick(userId, slide)}>Follow</button>
                  </div>)
          }
          {
              slide === 'followTiktok' && (
                  <div className='slide-item'>
                  <div className='channel-title'>FOLLOW US ON TIKTOK</div>
                  <div className='channel-desc'>Stay updated with the latest news</div>
                  <button onClick={() => handleClick(userId, slide)}>Follow</button>
                  </div>)
          }
          {
              slide === 'followYoutube' && (
                  <div className='slide-item'>
                  <div className='channel-title'>FOLLOW US ON YOUTUBE</div>
                  <div className='channel-desc'>Stay updated with the latest news</div>
                  <button onClick={() => handleClick(userId, slide)}>Follow</button>
                  </div>)
          }
          {
              slide === 'followBinance' && (
                  <div className='slide-item'>
                  <div className='channel-title'>FOLLOW US ON SQUARE BINANCE</div>
                  <div className='channel-desc'>CEO C World On Square Binance</div>
                  <button onClick={() => handleClick(userId, slide)}>Follow</button>
                  </div>)
          }
          
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default SliderCarousel;