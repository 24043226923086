import React, { useState } from 'react';
import Point from './Point';
import { generateRandomString } from '../utils';
import cworldIcon  from '../images/cworld.svg';

// const BOT_URL = "https://t.me/crickethouse_bot";
const BOT_URL = "https://t.me/crickethouse_bot/cwgame";

const Friends = ({code, userId, friends}) => {
  const [linkText, setLinkText] = useState("COPY INVITE LINK");
  const referLink = `${BOT_URL}?startapp=${code}`;
  const friendCount = userId === "1692396344" ? 900 + friends.length : friends.length;
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setLinkText("COPIED");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <div className='friend-page'>
      <div className='title'>
        <img src={cworldIcon} alt=""/>
        <h2>SIMULATION WORLD</h2>
      </div>
      <div className='description'>"Invite more friends to join and receive even greater rewards."</div>
      <div className='invited-friends'>
        <div className='friend-numbers'>{friendCount ? (`${friendCount} friends`) : ''}</div>
        <div className='friend-list'>
        {friends.length > 0 && friends.map( (friend, key) => (
          <div key={key} className='friend-item'>
             <div className='friend-name'>
                <span className='user-name'>{friend.userName || generateRandomString(8)}</span>
              </div>
              <div className='friend-point'><Point point={friend.accountAge ? Math.ceil(0.05*friend.accountAge) : 0}/></div>
          </div>
        ))}
        </div>
      </div>
      <div className='copy-link'>
        <button className='btn-copy-link' onClick={() => copyToClipboard(referLink)}>{linkText}</button>
      </div>
    </div>
  );
};

export default Friends;
