import React from 'react';
import logo from '../images/logo.jpg';

const Point = ({ point }) => {
    return (
      <span className='point-number'>{point} <img src={logo} alt=""/></span>
    );
};

export default Point;
