import React, { useState } from 'react';
import axios from 'axios';
import Point from './Point';
import { baseUrl } from '../constant';
import cworldIcon  from '../images/cworld.svg';
import binanceIcon  from '../images/binance.svg';
import okxIcon  from '../images/okx.svg';
import bitgetIcon  from '../images/bitget.svg';

const ACTION_URL = {
  'registerBinance': 'https://accounts.binance.com/register?ref=532445369',
  'registerOkx': 'https://www.okx.com/join/87352546',
  'registerBitget': 'https://partner.bitget.com.vn/bg/EWRYN6'
}

const Mission = ({userId, address = ""}) => {
  const [walletAddress, setWalletAddress] = useState(address);
  const [editing, setEditing] = useState(!address);
  
  const registerAccount = (actionType) => {
    handleAddPoint(actionType);
    window.open(ACTION_URL[actionType], '_blank', 'noopener,noreferrer');
  };

  const handleAddPoint = async (actionType) => {
    try {
      console.log("handle add point", userId, actionType);
      await axios.post(`${baseUrl}/addPoints`, {
          "userId": userId,
          "actionType": actionType
      });
    } catch (error) {
        console.error('Error fetching joined users:', error);
    }
  };
  const handleInputChange = (event) => {
    setWalletAddress(event.target.value);
  };
  const handleConfirm = async () => {
    try {
      await axios.post(`${baseUrl}/updateWallet`, {
          "userId": userId,
          "address": walletAddress
      }).then( () => {
        setEditing(false);
      });
    } catch (error) {
        console.error('Error add wallet address', error);
    }
  };
  return (
    <div className='mission-page'>
      <div className='title'>
        <img src={cworldIcon} alt=""/>
        <span>community</span>
      </div>
      <div className='description'>Note: Only registered uids who introduce the referral link will receive a $10 USDT bonus</div>
      <div className='register-item'>
        <div className='left-item'>
          <img src={binanceIcon} alt=""/>
        </div>
        <div className='right-item'>
          <div className='register-account' onClick={() => registerAccount("registerBinance")}>
            <p><strong>Register for a BINANCE account</strong></p>
            <p>Successful KYC to receive $10 USDT</p>
          </div>
          <div className='register-bonus'>+<Point point={3000}/></div>
        </div>
      </div>
      <div className='register-item'>
        <div className='left-item'>
          <img src={okxIcon} alt=""/>
        </div>
        <div className='right-item'>
          <div className='register-account' onClick={() => registerAccount("registerOkx")}>
            <p><strong>Register for a OKX account</strong></p>
            <p>Successful KYC to receive $10 USDT</p>
          </div>
          <div className='register-bonus'>+<Point point={3000}/></div>
        </div>
      </div>
      <div className='register-item'>
        <div className='left-item'>
          <img src={bitgetIcon} alt=""/>
        </div>
        <div className='right-item'>
          <div className='register-account' onClick={() => registerAccount("registerBitget")}>
            <p><strong>Register for a BITGET account</strong></p>
            <p>Bonus at the registration link</p>
          </div>
          <div className='register-bonus'>+<Point point={3000}/></div>
        </div>
      </div>
      <div className='wallet-address'>
        <p className='wallet-desc'>Please write down your BEP20 Metamask wallet or BEP20 Trust wallet address to receive AIRDROP!</p>
        <p className='wallet-input'>
          {(walletAddress && !editing) && (<span>{walletAddress}</span>)}
          {editing && (<input 
            type="text" 
            value={walletAddress} 
            onChange={handleInputChange} 
            placeholder="Address:"
          />)}
        </p>
        <p className='wallet-button'>
          {editing ?
            (<button className='btn-confirm' onClick={() => handleConfirm()}>Confirm</button>) :
            (<button className='btn-confirm' onClick={() => setEditing(true)}>Edit</button>)
          }
        </p>
      </div>
    </div>
  );
};

export default Mission;
